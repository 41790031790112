import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../components/containers/card';
import { Columns, Content, Rows } from '../../components/grid';

function StatPieCharts({ color, coloured, pieCharts }) {
  return (
    <Columns count="1">
      <Content>
        <Card
          {...(coloured && { className: `card--${color}` })}
          styleType="solid"
        >
          <Columns colgap count="4">
            {pieCharts.map(({ percentValue, postChartText }, index) => (
              <Rows>
                <div className="relative">
                  <svg viewBox="0 0 36 36" className="piechart">
                <path
                    className="piechart__circle piechart__circle--bg"
                    d="M18 2.0845
  a 15.9155 15.9155 0 0 1 0 31.831
  a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                <path
                    className={`piechart__circle piechart__circle--${color}`}
                    strokeDasharray={`${percentValue}, 100`}
                    d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
              </svg>
                  <p className="h2 piechart__value">
                {percentValue}
                %
                </p>
                </div>
                <p className="text--center">{postChartText}</p>
              </Rows>
            ))}
          </Columns>
        </Card>
      </Content>
    </Columns>
  );
}

StatPieCharts.defaultProps = {
  coloured: false,
};

StatPieCharts.propTypes = {
  /**
   * Colour for the charts
   */
  color: PropTypes.oneOf([
    'nebula',
    'pebble',
    'ragnarok',
    'terra',
    'coral',
    'arcade',
    'qwerty',
    'midgard',
    'core',
    'core-light',
  ]),
  /**
   * Whether the background is coloured
   */
  coloured: PropTypes.bool,
};

export default StatPieCharts;
