import React from "react"
import PropTypes from "prop-types"
import Card from "../../components/containers/card"
import Copy from "../../components/content/copy"
import { Columns, Content } from "../../components/grid"

function StatArea({ stats, color }) {
  return (
    <Columns className="tab-mx-xs" count="3" rowgap colgap>
      {stats.map(({ id, statisticText, statisticAuthor }) => (
        <Card
          className={`card--${color} height--full`}
          key={id}
          styleType="solid"
        >
          <Columns count="1" className="card--statistic">
            <Content gap="sm" paddingLeft="none" paddingRight="none">
              <Copy contentToParse={statisticAuthor} />
              <Copy contentToParse={statisticText} />
            </Content>
          </Columns>
        </Card>
      ))}
    </Columns>
  )
}

StatArea.propTypes = {
  /**
   * Colour for the background
   */
  color: PropTypes.oneOf([
    "nebula",
    "pebble",
    "ragnarok",
    "terra",
    "coral",
    "arcade",
    "qwerty",
    "midgard",
    "core",
    "core-light",
  ]),
}

export default StatArea
