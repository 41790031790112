import React from 'react';
import StatisticsArticles from '../components-smart/statistics/articles';
import StatisticsCtaBar from '../components-smart/statistics/cta-bar';
import DoubleTextBlock from '../components-smart/statistics/double-text-block';
import StatPieCharts from '../components-smart/statistics/pie-charts';
import StatArea from '../components-smart/statistics/stat-area';
import Heading from '../components/content/heading';
import { Columns, Content } from '../components/grid';

const renderStatisticsItem = (item, pageColor) => {
  switch (item.strapi_component) {
    case 'common.double-text-block':
      return (
        <DoubleTextBlock
          key={`${item.strapi_component}-${item.id}`}
          heading={item.heading}
          leftText={item.leftText}
          rightText={item.rightText}
        />
      );

    case 'atoms.heading':
      return (
        <Columns
          className="mt-md"
          count="1"
          key={`${item.strapi_component}-${item.id}`}
        >
          <Content>
            <Heading preheading={item.preHeading}>
              {item.primaryHeading}
            </Heading>
          </Content>
        </Columns>
      );

    case 'statistics.articles':
      return (
        <StatisticsArticles
          key={`${item.strapi_component}-${item.id}`}
          color={pageColor}
          articles={item.individualArticle}
          columns={item.columns}
        />
      );

    case 'statistics.cta-bar':
      return (
        <StatisticsCtaBar
          key={`${item.strapi_component}-${item.id}`}
          primaryText={item.primaryText}
          secondaryText={item.secondaryText}
          button={item.button}
        />
      );

    case 'statistics.pie-charts':
      return (
        <StatPieCharts
          key={`${item.strapi_component}-${item.id}`}
          color={pageColor}
          coloured={item.coloured}
          pieCharts={item.singlePieChart}
        />
      );

    case 'statistics.stat-area':
      return (
        <StatArea
          key={`${item.strapi_component}-${item.id}`}
          color={pageColor}
          stats={item.statbox}
        />
      );

    default:
      return null;
  }
};

export default renderStatisticsItem;
