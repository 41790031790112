import React from 'react';
import Copy from '../../components/content/copy';
import Heading from '../../components/content/heading';
import { Columns, Content, Rows } from '../../components/grid';

function DoubleTextBlock({ heading, leftText, rightText }) {
  return (
    <Rows className="mt-md">
      <Columns count="3,1">
        <Content>
          <Heading preheading={heading.preHeading} level={2}>
            {heading.primaryHeading}
          </Heading>
        </Content>
      </Columns>
      <Columns rowgap count="2">
        <Content>
          <Copy contentToParse={leftText} />
        </Content>
        <Content>
          <Copy contentToParse={rightText} />
        </Content>
      </Columns>
    </Rows>
  );
}

export default DoubleTextBlock;
