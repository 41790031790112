import React from 'react';
import Card from '../../components/containers/card';
import { Columns, Content, Rows } from '../../components/grid';
import Copy from '../../components/content/copy';
import CtaGroup from '../../components/containers/cta-group';
import Button from '../../components/interactive/button';

function StatisticsPageCard({
  link,
  pageColor,
  shortPageTitle,
  shortPageDesc,
}) {
  return (
    <Card className="card--testimonial">
      <Rows>
        <Columns count="1">
          <Content>
            <p className="h4">{shortPageTitle}</p>
            <Copy contentToParse={shortPageDesc} />
            <CtaGroup>
              <Button href={`/statistics/${link}/`} color={pageColor}>
                View Statistics
              </Button>
            </CtaGroup>
          </Content>
        </Columns>
      </Rows>
    </Card>
  );
}

export default StatisticsPageCard;
