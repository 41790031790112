import React from "react"
import Carousel from "./default"
import StatisticsPageCard from "./statistics-page-card"

function StatisticsCarousel({ cardsData }) {
  const responsiveSlides = {
    full: 2,
    mid: 2,
    small: 1,
  }

  const cards = cardsData.map(
    ({ slug, shortPageTitle, shortPageDesc, pageColor }, index) => (
      <StatisticsPageCard
        shortPageTitle={shortPageTitle}
        shortPageDesc={shortPageDesc}
        pageColor={pageColor}
        link={slug}
        key={index}
      />
    ),
  )

  return <Carousel slidesToShow={responsiveSlides} centered slides={cards} />
}

export default StatisticsCarousel
