import React from 'react';
import Card from '../../components/containers/card';
import CtaGroup from '../../components/containers/cta-group';
import { Columns, Content } from '../../components/grid';
import Button from '../../components/interactive/button';

function StatisticsCtaBar({ primaryText, secondaryText, button }) {
  return (
    <Card className="my-md tab-mx-xs">
      <Columns count="2,1" alignment="center">
        <Content gap="sm">
          <p className="h4 text--color-purple">{primaryText}</p>
          <p>{secondaryText}</p>
        </Content>
        <Content>
          <CtaGroup alignment="end">
            <Button
              href={button.link}
              color={button.color}
              styletype={button.type}
            >
              {button.text}
            </Button>
          </CtaGroup>
        </Content>
      </Columns>
    </Card>
  );
}

export default StatisticsCtaBar;
