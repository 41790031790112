import React from "react"
import PropTypes from "prop-types"
import Card from "../../components/containers/card"
import Copy from "../../components/content/copy"
import { Columns, Content } from "../../components/grid"

function StatisticsArticles({ articles, columns, color }) {
  return (
    <Columns count={columns.toString()} className="tab-mx-xs" colgap>
      {articles.map(({ id, title, text, link }) => (
        <Card
          fullHeight
          link={link}
          key={id}
          className={`card--${color} height--full`}
          styleType="solid"
        >
          <div>
            <p className="text--strong mb-xs">{title}</p>
            <p className="text--small">{text}</p>
          </div>
        </Card>
      ))}
    </Columns>
  )
}

StatisticsArticles.propTypes = {
  /**
   * Colour for the article background
   */
  color: PropTypes.oneOf([
    "nebula",
    "pebble",
    "ragnarok",
    "terra",
    "coral",
    "arcade",
    "qwerty",
    "midgard",
    "core",
    "core-light",
  ]),
}

export default StatisticsArticles
