import React, { useLayoutEffect } from "react"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import Copy from "../../components/content/copy"
import Heading from "../../components/content/heading"
import { Columns, Content, Footer, Rows } from "../../components/grid"
import Main from "../../components/layout/main"
import Seo from "../../components/seo/seo"
import TryUsBar from "../../components/sections/try-us-bar"
import CtaGroup from "../../components/containers/cta-group"
import Button from "../../components/interactive/button"
import SectionBackground from "../../components/containers/section-background"
import HeadingAccent from "../../components/accents/heading-accent"
import StatisticsCarousel from "../../components-smart/carousel/statistics-page-carousel"
import renderStatisticsItem from "../../utils/render-statistics-item"

function StatisticsPage({ pageContext, data }) {
  const {
    pageColor,
    seo,
    heading,
    featuredStatBox,
    content,
    relatedPagesText,
  } = data.strapiStatsPages

  const { nodes: otherStats } = data.allStrapiStatsPages

  const seoData = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: `/statistics/${pageContext.slug}/`,
  }

  useLayoutEffect(() => {
    // Change colour of first word in title
    const parts = heading.headingText.split(" ")
    const sparklyBit = parts.splice(0, 3)
    const newSparklyBit = `<span class="text-gradient text-gradient__nebula">${sparklyBit.join(
      " ",
    )}</span>`
    parts.unshift(newSparklyBit)
    let result
    if (parts instanceof Array) {
      result = parts.join(" ")
      const headingObject = document.getElementById("statisticTitle")
      headingObject.innerHTML = result
    }
  })

  return (
    <Main>
      <Seo customData={seoData} />

      <header>
        <Section>
          <SectionBackground>
            <div className="hero-image--careers" />
          </SectionBackground>
          <SectionContent paddingBot="xs" hero>
            <Rows gap="xxl" className="mt-xxl">
              <Columns count="2,1" alignment="center">
                <Content gap="xl">
                  <Heading id="statisticTitle" className="mt-sm" level={1}>
                    {heading.headingText}
                  </Heading>
                  <Footer>
                    <CtaGroup>
                      {heading.buttons.map(
                        ({ color, link, text, type }, index) => (
                          <Button
                            key={`stats-heading-button-${index}`}
                            href={link}
                            styletype={type}
                            color={color}
                          >
                            {text}
                          </Button>
                        ),
                      )}
                    </CtaGroup>
                  </Footer>
                </Content>
              </Columns>
              <Columns className="mt-md" count="3">
                {featuredStatBox.map(({ image, text }, index) => {
                  const imgFile = getImage(image.localFile)
                  return (
                    <Content key={index}>
                      <div>
                        <GatsbyImage
                          image={imgFile}
                          alt={image.alternativeText}
                        />
                      </div>
                      <Copy contentToParse={text} />
                    </Content>
                  )
                })}
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingTop="sm">
          <Rows>
            {content.map(item => renderStatisticsItem(item, pageColor))}
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingTop="none" paddingBot="sm">
          <Rows>
            <Columns count="1">
              <Content paddingLeft="sm">
                <Heading preheading={relatedPagesText.preHeading}>
                  {relatedPagesText.primaryHeading}
                </Heading>
              </Content>
              <StatisticsCarousel cardsData={otherStats} />
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <TryUsBar />
    </Main>
  )
}

export const query = graphql`
  query StatsPageQuery($id: String!) {
    allStrapiStatsPages(filter: { id: { ne: $id } }) {
      nodes {
        slug
        shortPageTitle
        shortPageDesc
        pageColor
      }
    }
    strapiStatsPages(id: { eq: $id }) {
      content
      pageColor
      seo {
        metaTitle
        metaDesc
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      heading {
        headingText
        postHeadingText
        buttons {
          color
          link
          text
          type
        }
      }
      relatedPagesText {
        preHeading
        primaryHeading
      }
      featuredStatBox {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 160)
            }
          }
          alternativeText
        }
        text
      }
    }
  }
`

export default StatisticsPage
